import React, { useCallback, useEffect, useState } from 'react'
import { useUserContext } from '@condofy/next/store/user'
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  colors,
  Flex,
  Pagination,
  DataTable,
  DateText
} from '@condofy/components'
import { toggleModal } from 'Actions/modals'
import { ADD_ANNOUNCEMENT_MESSAGE_MODAL } from 'Const'
import { useDispatch } from 'react-redux'
import { AnnouncementStatusChip } from './AnnouncementStatusChip'
import { AnnouncementsListFilters } from './AnnouncementListFilters'
import { condofyProtected } from '@condofy/next/service/instance'
import { getToken } from '@condofy/next/vendor/@condofy/token'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'
import { useSnackbar } from '@condofy/next/hooks/useSnackbar'

import styled from 'styled-components'


const MetaButtonDelete = styled(Button)`
&.MuiButton-contained {
  background: ${colors.light300};
  color: ${colors.primary};
}
`


export const AnnouncementList = ({ className }) => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState('')
  const { enqueueSnackbar } = useSnackbar()
  
  const [isExcludeModalOpen, setIsExcludeModalOpen] = useState(false)
  const [excludeSelectedId, setExcludeSelectedId] = useState(null)
  const token = getToken()
  
  
  const [loading, setLoading] = useState(false);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [announcementList, setAnnouncementList] = useState(0);


async function updateDataSource() {
  setLoading(true)
  const response = await fetch(
    `${process.env.REACT_APP_API_HOST}/v1/condominiums/${currentCondominium.id}/announcements/search?pageNumber=${page}&recordsPerPage=${recordsPerPage}`,
    {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({
      }),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
  )

  if (response.ok) {
    const responseData = await response.json()

    if (responseData.data !== undefined) {
      setAnnouncementList(responseData.data)
    } else {
      setAnnouncementList([])
    }

    setTotalRecords(responseData.paging.totalRecords)
    setLoading(false)
    return
  }
  setLoading(false)
}

  const deleteSignature = () => {
      setLoading(true)
      condofyProtected
        .delete(
          `/v1/condominiums/${currentCondominium.id}/announcements/${excludeSelectedId}`
        )
        .then(() => {
          updateDataSource()
          setIsExcludeModalOpen(false)
          setLoading(false)
          setExcludeSelectedId(null)
          enqueueSnackbar('Rascunho removido com sucesso', {
            variant: 'success'
          })
        })
        .catch((err) => {
          setLoading(false)
          enqueueSnackbar('Erro ao remover o rascunho', {
            variant: 'error'
          })
          console.log(err)
        })
    }

useEffect(()=>{
    if(!isExcludeModalOpen){
      setExcludeSelectedId(null)
    }
  },[isExcludeModalOpen])

  const {
    state: {
      isSuperAdmin,
      isAdmin,
      currentCondominium,
      announcement: { items, metadata, createModalOpen }
    },
    actions: {
      listAnnouncement,
      openCreateAnnouncementModal,
      openEditAnnouncementModal
    }
  } = useUserContext()

  useEffect(() => {
    setRecordsPerPage(metadata.recordsPerPage)
    setLoading(metadata.loading)
    setTotalRecords(metadata.totalRecords)
    setAnnouncementList(items)
  }, [metadata.loading, items])


  const administratorUser = isSuperAdmin || isAdmin
  const stableListAnnouncement = useCallback(listAnnouncement, [])

  useEffect(() => {
    if (currentCondominium.id) {
      stableListAnnouncement({
        currentCondominium,
        page: page,
        recordsPerPage: 10,
        generalSearch: search,
        generalStatus: status
      })
    }
  }, [currentCondominium, page, stableListAnnouncement, search, status])

  useEffect(() => {
    if (currentCondominium.id && !createModalOpen) {
      setTimeout(
        () =>
          stableListAnnouncement({
            currentCondominium,
            page: page,
            recordsPerPage: 10,
            generalSearch: search,
            generalStatus: status
          }),
        500
      )
    }
  }, [createModalOpen])

  const handleEdit = (announcement) => {
    if (administratorUser && announcement.status === 'A') {
      openEditAnnouncementModal(announcement)
    } else {
      dispatch(toggleModal(ADD_ANNOUNCEMENT_MESSAGE_MODAL, true, announcement))
    }
  }

  const columns = [
    {
      headerName: 'Titulo',
      field: 'title'
    },
    {
      headerName: 'Autor',
      valueGetter: ({ row }) => row.createdBy?.name
    },
    {
      headerName: 'Data',
      renderCell: ({ row }) => <DateText format='long' date={row.createdAt} />
    },
    {
      headerName: 'Status',
      renderCell: ({ row }) => <AnnouncementStatusChip status={row.status} />
    },
    {
      headerName: '',
      renderCell: ({ row }) => (row.status === 'A' ?  
      <MetaButtonDelete
        color='light'
        startIcon={<DeleteIcon size='small' />}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsExcludeModalOpen(true)
          setExcludeSelectedId(row.id)
        }}
      >
        Excluir
      </MetaButtonDelete> 
      : '')
    }
  ]
  return (
    <>
    <Dialog
          open={isExcludeModalOpen}
          onClose={() => setIsExcludeModalOpen(false)}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>Remover Rascunho</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              Deseja remover o rascunho?<br></br>
              <b>Atenção! Essa ação é irreversível.</b>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <div
              style={{
                justifyContent: 'space-between',
                display: 'flex',
                width: '100%',
                padding: '12px'
              }}
            >
              <Button
                color='secondary'
                style={{ height: '100%' }}
                disabled={false}
                onClick={() => setIsExcludeModalOpen(false)}
              >
                Cancelar
              </Button>

              <Button
                color='light'
                style={{ background: 'red', color: 'white', height: '100%' }}
                loading={loading}
                disabled={!excludeSelectedId}
                onClick={deleteSignature}
              >
                Excluir
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      <Card className={className}>
        <CardHeader
          title='Comunicados'
          action={
            <>
              <AnnouncementsListFilters
                search={search}
                onSearch={setSearch}
                status={status}
                onStatus={setStatus}
              />
              {administratorUser && (
                <Button onClick={openCreateAnnouncementModal}>+ Novo</Button>
              )}
            </>
          }
        />
        <CardContent>
          <DataTable
            size='small'
            variant='borderless'
            loading={metadata.loading}
            columns={columns}
            rows={announcementList}
            onRowClick={handleEdit}
          />
        </CardContent>
      </Card>
      <Flex width='100%' justify='flex-end'>
        <Pagination
          page={page}
          totalResults={totalRecords}
          pageSize={recordsPerPage}
          onChange={(event, page) => setPage(page)}
          disabled={loading}
        />
      </Flex>
    </>
  )
}
