import React, { useEffect, useState } from 'react'
import { Form, FormRow, TextField, MenuItem } from '@condofy/components'
import { ProvinceSelector } from '@condofy/next/components'
import { cnpjMask, cepMask } from '@condofy/next/containers'

const translatePlan = (planId) => {
  switch (planId) {
    case 'starter':
    case '2':
      return 'starter'
    case 'premium':
    case '3':
      return 'premium'
    default:
      return false
  }
}

export const CondominiumForm = ({
  condominium = {
    type: {},
    address: {},
    plan: {},
    paymentSubscriptionId: ''
  },
  onChange: emitChangeEvent = () => {}
}) => {
  const emitChange = (value) => {
    emitChangeEvent({ ...condominium, ...value })
  }

  const [
    showPaymentSubscriptionId,
    setShowPaymentSubscriptionId
  ] = useState(false)

  useEffect(() => {
    setShowPaymentSubscriptionId(
      Boolean(translatePlan(condominium.plan.id) === 'starter')
    )
  }, [condominium.paymentSubscriptionId])

  const handleUpdate = (event) => {
    const { name, value } = event.target
    emitChange({ [name]: value })
  }

  const handleAddressUpdate = (event) => {
    const { name, value } = event.target
    emitChange({
      address: {
        ...condominium.address,
        [name]: value
      }
    })
  }

  const handleTypeChange = (event) => {
    emitChange({ type: { id: event.target.value } })
  }

  const handlePlanUpdate = (event) => {
    setShowPaymentSubscriptionId(Boolean(event.target.value === 'starter'))
    emitChange({ plan: { id: event.target.value } })
  }

  const handlePaymentSubscriptionIdUpdate = (event) => {
    emitChange({ paymentSubscriptionId: event.target.value })
  }

  return (
    <Form>
      <FormRow>
        <TextField
          label='CNPJ'
          name='cnpj'
          value={condominium.cnpj ? cnpjMask(condominium.cnpj) : ''}
          onChange={handleUpdate}
          disabled={Boolean(condominium.id)}
          required
          inputProps={{
            maxLength: 18
          }}
        />
      </FormRow>
      <FormRow>
        <TextField
          label='Nome'
          name='name'
          value={condominium.name || ''}
          onChange={handleUpdate}
          placeholder='Nome do Condomínio'
          required
        />
      </FormRow>
      <FormRow>
        <TextField
          select
          name='id'
          label='Tipo'
          value={condominium.type.id || '1'}
          onChange={handleTypeChange}
        >
          <MenuItem value='1'>Comercial</MenuItem>
          <MenuItem value='2'>Residencial</MenuItem>
          <MenuItem value='3'>Outro</MenuItem>
        </TextField>
      </FormRow>
      <FormRow>
        <TextField
          label='Endereço'
          name='street'
          value={condominium.address.street || ''}
          onChange={handleAddressUpdate}
          placeholder='Endereço'
          required
        />
      </FormRow>
      <FormRow>
        <TextField
          label='Número'
          name='number'
          value={condominium.address.number || ''}
          onChange={handleAddressUpdate}
          placeholder='Número'
          required
        />
      </FormRow>
      <FormRow>
        <TextField
          label='Bairro'
          name='district'
          value={condominium.address.district || ''}
          onChange={handleAddressUpdate}
          placeholder='Bairro'
          required
        />
      </FormRow>
      <FormRow>
        <TextField
          label='Cidade'
          name='city'
          value={condominium.address.city || ''}
          onChange={handleAddressUpdate}
          placeholder='Cidade'
          required
        />
      </FormRow>
      <FormRow>
        <ProvinceSelector
          label='Estado'
          name='state'
          value={condominium.address.state || ''}
          onChange={handleAddressUpdate}
          placeholder='Estado'
        />
      </FormRow>
      <FormRow>
        <TextField
          label='Código Postal'
          name='zipCode'
          value={
            condominium.address.zipCode
              ? cepMask(condominium.address.zipCode)
              : ''
          }
          onChange={handleAddressUpdate}
          required
          inputProps={{
            maxLength: 9
          }}
        />
      </FormRow>
      <FormRow>
        <TextField
          label='Código'
          name='code'
          value={condominium.code || ''}
          onChange={handleUpdate}
          placeholder='Código'
          disabled={Boolean(condominium.id)}
          required
        />
      </FormRow>
      <FormRow>
        <TextField
          select
          label='Plano'
          name='id'
          value={translatePlan(condominium.plan.id) || ''}
          onChange={handlePlanUpdate}
        >
          <MenuItem value='starter'>Starter</MenuItem>
          <MenuItem value='premium'>Premium</MenuItem>
        </TextField>
        {showPaymentSubscriptionId && (
          <TextField
            label='ID da Assinatura STRIPE'
            name='paymentSubscriptionId'
            value={condominium.paymentSubscriptionId || ''}
            onChange={handlePaymentSubscriptionIdUpdate}
            placeholder='*********************'
          />
        )}
      </FormRow>
    </Form>
  )
}
