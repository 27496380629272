import React, { useEffect, useState, useCallback } from 'react'
import { NewButton } from '@condofy/next/components'
import { CondominiumListItem } from '@condofy/next/containers/admin'
import { useUserContext } from '@condofy/next/store/user'
import { Select } from '@condofy/components'
import { MenuItem } from '@mui/material'
import {
  TabToolbar,
  Card,
  CardHeader,
  SearchTextField,
  CardContent,
  DataTable,
  Flex,
  Pagination
} from '@condofy/components'

export const CondominiumsTab = (props) => {
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState(null)
  const [plan, setPlan] = useState(null)
  const [page, setPage] = useState(1)

  const {
    state: {
      condominium: { items, metadata }
    },
    actions: { listCondominium, openCreateCondominiumModal }
  } = useUserContext()
  const stableListCondominium = useCallback(listCondominium, [])

  useEffect(() => {
    stableListCondominium({ PageNumber: page, generalSearch: search, planIds:[plan], isInactive: status })
  }, [search, page, stableListCondominium, plan, status])

  const columns = [
    { field: 'name', headerName: 'Nome' },
    { field: 'address', headerName: 'Endereço' },
    { field: 'cnpj', headerName: 'CNPJ' },
    { field: 'type', headerName: 'Tipo' },
    { field: 'code', headerName: 'Código' },
    { field: 'plan', headerName: 'Plano' },
    { expand: true }
  ]

  const planSelectOptions = [
    { value: null, label: 'Não especificado' },
    { value: 2, label: 'Starter' },
    { value: 3, label: 'Premium' }
  ]
  
  const statusSelectOptions = [
    { value: null, label: 'Não especificado' },
    { value: false, label: 'Ativo' },
    { value: true, label: 'Inativo' }
  ]

  return (
    <>
      <TabToolbar>
        <SearchTextField
          size='small'
          onChange={(event) => setSearch(event.target.value)}
          value={search}
        />
        <Select
          label={'Status'}
          sx={{ width: '270px' }}
          value={status}
          onChange={(event) => setStatus(event.target.value)}
        >
          {statusSelectOptions.map((p) => (
            <MenuItem key={p.value} value={p.value}>
              {p.label}
            </MenuItem>
          ))}
        </Select>
        <Select
          label={'Plano'}
          sx={{ width: '270px' }}
          value={plan}
          onChange={(event) => setPlan(event.target.value)}
        >
          {planSelectOptions.map((p) => (
            <MenuItem key={p.value} value={p.value}>
              {p.label}
            </MenuItem>
          ))}
        </Select>
        <NewButton onClick={openCreateCondominiumModal}>Novo</NewButton>
      </TabToolbar>
      <Card {...props}>
        <CardHeader title='Condomínios' />
        <CardContent>
          <Flex direction='column' spacingY={3}>
            <DataTable
              variant='borderless'
              size='small'
              columns={columns}
              loading={metadata.loading}
              {...props}
            >
              {items.map((condominium) => (
                <CondominiumListItem
                  key={condominium.id}
                  condominium={condominium}
                />
              ))}
            </DataTable>
            <Flex width='100%' justify='flex-end'>
              <Pagination
                totalResults={metadata.totalRecords}
                pageSize={metadata.recordsPerPage}
                page={page}
                disabled={metadata.loading}
                onChange={(event, page) => setPage(page)}
              />
            </Flex>
          </Flex>
        </CardContent>
      </Card>
    </>
  )
}
