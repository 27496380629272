import { condofyProtected } from './instance'
import moment from 'moment'
import { jsonToFormData } from '@condofy/next/service/formData'

export const getBalance = ({ currentCondominium, year: Year }) =>
  condofyProtected.post(
    `/v1/condominiums/${currentCondominium.id}/entries/annual-balance`,
    {},
    {
      params: {
        Year
      }
    }
  )

export const getExpectedValues = ({
  currentCondominium,
  //currentAccount,
  currentMonth
}) =>
  condofyProtected.post(
    `v1/condominiums/${currentCondominium.id}/entries/expected-realized`,
    {
      //bankAccountId: currentAccount.bankAccountId,
      startDate: moment(currentMonth)
        .startOf('month')
        .format('YYYY-MM-DD 00:00'),
      endDate: moment(currentMonth).endOf('month').format('YYYY-MM-DD 23:59')
    }
  )

export const downloadExcel = ({
  currentCondominium,
  // currentMonth,
  bankAccountId,
  entryType,
  initialDate,
  finalDate
}) => {
  // const entryDateStart = startOfMonth(currentMonth)
  // const entryDateEnd = endOfMonth(currentMonth)
  const entryDateStart = moment(initialDate).format('YYYY-MM-DDT00:00:00')
  const entryDateEnd = moment(finalDate).format('YYYY-MM-DDT23:59:00')

  console.log('downloadExcel', {
    currentCondominium,
    // currentMonth,
    bankAccountId,
    entryType,
    initialDate,
    finalDate
  });

  let body = {
    dueDateStart: entryDateStart,
    dueDateEnd: entryDateEnd,
    settleDateStart: entryDateStart,
    settleDateEnd: entryDateEnd,
    statuses: [3]
  }

  switch(entryType)  {
    case 0: // to pay
      body = {
        dueDateStart: entryDateStart,
        dueDateEnd: entryDateEnd,
        statuses: [2,3],
        fundingTypes: [2]
      }
      break;
    case 1: // to receive
      body = {
        dueDateStart: entryDateStart,
        dueDateEnd: entryDateEnd,
        statuses: [2,3],
        fundingTypes: [1]
      }
      break;
    case 2: // detailed
      body = {
        settleDateStart: entryDateStart,
        settleDateEnd: entryDateEnd,
        statuses: [4],
        fundingTypes: []
      }
      break;
    default:
  }

  if (bankAccountId) body.bankIds = [bankAccountId]

  return condofyProtected.post(
    `/v1/condominiums/${currentCondominium.id}/entries/download-excel`,
    body,
    {
      responseType: 'arraybuffer',
      params: {
        typeDownload: entryType
      }
    }
  )
}

export const downloadToPay = (payload) =>
  downloadExcel({ entryType: 0, ...payload })

export const downloadToReceive = (payload) =>
  downloadExcel({ entryType: 1, ...payload })

export const downloadToDetailed = (payload) =>
  downloadExcel({ entryType: 2, ...payload })

export const listEntriesBalance = ({ condominiumId }) =>
  condofyProtected.get(`/v1/condominiums/${condominiumId}/entries/balance`)

export const listEntries = ({ condominiumId }) =>
  condofyProtected.get(`/v1/condominiums/${condominiumId}/entries/search`)

export const listEntryCategories = () =>
  condofyProtected.get(`/v1/entry-categories`)

export const createFinanceEntry = ({ condominiumId, body }) =>
  condofyProtected.post(
    `/v1/condominiums/${condominiumId}/entries`,
    jsonToFormData(body),
    {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
  )

export const updateFinanceEntry = ({ condominiumId, entryId, body }) =>
  condofyProtected.put(
    `/v1/condominiums/${condominiumId}/entries/${entryId}`,
    jsonToFormData(body),
    {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
  )

export const deleteFinanceEntry = ({ condominiumId, entryId }) =>
  condofyProtected.delete(
    `/v1/condominiums/${condominiumId}/entries/${entryId}`
  )
