import { condofy, condofyProtected } from './instance'
import { normalizeObject } from '~/lib/util/normalizeObject'

export const fetchUnitListByCondominiumId = ({
  condominiumId,
  onError: emitErrorEvent = (e) => e,
  onSuccess: emitSuccessEvent = (e) => e
}) => {
  condofyProtected
    .get(`/v1/condominiums/${condominiumId}/units`)
    .then(({ data }) => emitSuccessEvent(normalizeObject(data)))
    .catch((error) => emitErrorEvent(error))
}

export const fetchUnitListWithUsersByCondominiumId = ({
  condominiumId,
  onError: emitErrorEvent = (e) => e,
  onSuccess: emitSuccessEvent = (e) => e
}) => {
  condofyProtected
    .get(`/v1/condominiums/${condominiumId}/units?includeUsers=true`)
    .then(({ data }) => emitSuccessEvent(normalizeObject(data)))
    .catch((error) => emitErrorEvent(error))
}

export const getCondominiumByCode = ({ code }) =>
  condofyProtected.get(`/v1/condominiums/${code}`)

export const searchCondominium = () => condofy.post('/v1/condominiums/search')

export const deleteCondominium = (payload) =>
  condofyProtected.delete(`/v1/condominiums/${payload.id}`)

export const inactiveCondominium = (payload) =>
  condofyProtected.patch(`/v1/condominiums/${payload.id}/inactive`)

export const activeCondominium = (payload) =>
  condofyProtected.patch(`/v1/condominiums/${payload.id}/active`)

export const createCondominium = ({ condominium }) =>
  condofyProtected.post(`/v1/condominiums`, condominium)

export const editCondominium = ({ condominium }) =>
  condofyProtected.put(`/v1/condominiums/${condominium.id}`, condominium)

export const condominiumFullList = () =>
  condofyProtected.get(`/v1/condominiums/user`)

export const listCondominium = (
  payload = { PageNumber: 1, generalSearch: '' }
) => {
  const { PageNumber, generalSearch, isInactive, planIds } = payload

  const body = {
    generalSearch
  }

  if( typeof isInactive === 'boolean' ) body.isInactive = isInactive
  if (
    Array.isArray(planIds) &&
    planIds.length &&
    planIds.every((id) => typeof id === 'number')
  )
  body.planIds = planIds

  return condofyProtected.post(
    `/v1/condominiums/search`,
    { ...body },
    {
      params: {
        PageNumber,
        includeManagers: true
      }
    }
  )
}
